import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { DataService } from "../../services/data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VehicleEditComponent } from "../vehicle-edit/vehicle-edit.component";

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  vehicles: any[] = [];
  sub: Subscription;

  page: number = 1;
  pageSize: number = 10;

  constructor(
    private dataService: DataService,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.updateList();
  }

  updateList() {
    this.sub = this.dataService.getVehicle().subscribe(result => {
      this.vehicles = result;
    });
  }

  get loading() {
    return this.sub && !this.sub.closed;
  }

  editVehicle(v: any = null) {
    const ref = this.modal.open(VehicleEditComponent);
    if (v) {
      ref.componentInstance.vehicle = v;
    }
    ref.result.then(value => {
      this.updateList();
    }, () => {});

  }
}

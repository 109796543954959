<div class="wrapper" style="">

  <div style="display: flex;flex-direction: column;align-items: center;">
    <div style="margin-bottom: 60px; text-align: center"><img style="height: auto; width: 440px;" src="/assets/images/iDreamsLogo.svg" class="" alt="">
    </div>

    <form action="">
      <div class="signintext">Select a project</div>
      <select name="p" id="" class="form-control custom_dropdown mb-4" [(ngModel)]="project">
        <option *ngFor="let p of projects" [ngValue]="p" >{{p.project_name}}</option>
      </select>
      <button (click)="chooseProject()" class="btn btn-warning text-white w-100">SUBMIT</button>
      <button (click)="cancel()" class="btn cancel-btn text-white w-100 mb-3">CANCEL</button>
    </form>
  </div>

</div>

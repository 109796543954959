import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public username: string = '';
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.username = JSON.parse(localStorage.getItem('username'));
  }

  public isMenuCollapsed = true;

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}

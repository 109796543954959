import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app.component';
import { LoginComponent } from "./components/login/login.component";
import { UserComponent } from "./components/user/user.component";
import { AppConfig } from "./app.config";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { JwtInterceptor } from "./services/jwt.interceptor";
import { AuthService } from "./services/auth.service";
import { UserGuard } from "./services/user.guard";
import { StorageService } from "./services/storage.service";
import { SplitPipe } from "./pipes/split.pipe";
import { NgbActiveModal, NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { UsersComponent } from './components/users/users.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { DictPipe } from './pipes/dict.pipe';
import { VehiclesComponent } from './components/vehicles/vehicles.component';
import { VehicleEditComponent } from './components/vehicle-edit/vehicle-edit.component';
import { WearableEditComponent } from './components/wearable-edit/wearable-edit.component';
import { WearablesComponent } from './components/wearables/wearables.component';
import { ChooseProjectComponent } from './components/choose-project/choose-project.component';
import { InfoModalComponent } from './components/modals/info/info.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ChooseProjectComponent,
    UserComponent,
    UsersComponent,
    UserEditComponent,
    DictPipe,
    VehiclesComponent,
    VehicleEditComponent,
    WearableEditComponent,
    WearablesComponent,
    InfoModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPaginationModule,
    HttpClientModule

  ],
  providers: [
    AuthService,
    AppConfig,
    NgbActiveModal,
    NgbModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    UserGuard,
    StorageService,
    SplitPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

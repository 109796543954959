import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { DataCache } from './data-cache';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public user: any;

  private _project: any;

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private storage: StorageService,
  ) {
  }

  get project(): any {

    if (!this._project) {
      this._project = this.storage.get('project');
    }

    return this._project;
  }

  set project(value: any) {//
    this._project = value;
  }

  get<T>(endpoint: string, ...args) {
    return this.http.get<T>(this.config.getEndpoint(endpoint), ...args);
  }

  post<T>(endpoint: string, body: any, ...args) {
    return this.http.post<T>(this.config.getEndpoint(endpoint), body, ...args);
  }

  put<T>(endpoint: string, body: any, ...args) {
    return this.http.put<T>(this.config.getEndpoint(endpoint), body, ...args);
  }

  getProjectList(): Observable<any> {
    return this.get('project');
  }

  editUser(params) {
    return this.post('user', params);
  }

  inviteUser(user) {

    const params = {
      'user_short_ids': [user.short_id],
      'project_id': this.project.project_id
    };
    return this.post('user/invite', params);
  }

  getUser(short_id?: string): Observable<any[]> {

    let params = new HttpParams()
      .set('project_id', this.project.project_id);

    if (short_id) {
      params = params.set('short_id', short_id);
    }
    return this.get('user', { params });
  }

  getVehicle(uuid: string = null): Observable<any[]> {
    let params = new HttpParams()
      .set('project_id', this.project.project_id);

    if (uuid) {
      params = params.set('vehicle_uuid', uuid);
    }

    return this.get('vehicle', { params });
  }

  editVehicle(params) {
    return this.post('vehicle', params);
  }

  attachVehicle(uid, vehicle_uuid): Observable<any> {
    let body = {
      "vehicle_uuid": vehicle_uuid,
      "short_id": uid
    }
    return this.post('vehicle/attach', body);
  }

  detachVehicle(uid, vehicle_uuid): Observable<any> {
    let body = {
      "vehicle_uuid": vehicle_uuid,
      "short_id": uid
    }
    return this.post('vehicle/detach', body);
  }

  getWearable(uuid: string = null): Observable<any[]> {
    let params = new HttpParams()
      .set('project_id', this.project.project_id);

    if (uuid) {
      params = params.set('wearable_uuid', uuid);
    }

    return this.get('wearable', { params });
  }

  editWearable(params) {
    return this.post('wearable', params);
  }

  attachWearable(uid, wearable_uuid): Observable<any> {
    let body = {
      "wearable_uuid": wearable_uuid,
      "short_id": uid
    }
    return this.post('wearable/attach', body);
  }

  detachWearable(uid, wearable_uuid): Observable<any> {
    let body = {
      "wearable_uuid": wearable_uuid,
      "short_id": uid
    }
    return this.post('wearable/detach', body);
  }

}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../services/data.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { VehicleEditComponent } from "../vehicle-edit/vehicle-edit.component";
import { WearableEditComponent } from "../wearable-edit/wearable-edit.component";
import { InfoModalComponent } from '../modals/info/info.component';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  user: any;
  form: FormGroup;
  autoShortname = true;
  sub: Subscription;

  vehicles: any[] = [];
  wearables: any[] = [];

  constructor(
    public modal: NgbActiveModal,
    public modalService: NgbModal,
    private dataService: DataService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {

    const user = this.user || {};

    this.autoShortname = !this.user || !this.user?.short_name;

    combineLatest([this.getVehicles(), this.getWearables()]).subscribe(() => {
      this.form = this.fb.group({
        first_name: [user.first_name || ''],
        last_name: [user.last_name || ''],
        short_name: [user.short_name || '', Validators.maxLength(10)],
        email: [user.email || ''],
        gender_type: [user.gender_type || 'male'],
        age_type: [user.age_type || 'above_30'],
        vehicle_uuid: [user.vehicle && user.vehicle.vehicle_uuid || null],
        wearable_uuid: [user.wearable && user.wearable.wearable_uuid || null]
      });
    });
  }

  setAgeType(age: string) {
    this.form.get('age_type').setValue(age);
  }

  get age() {
    return this.form.get('age_type').value;
  }

  setGender(gender: string) {
    this.form.get('gender_type').setValue(gender);
  }

  get gender() {
    return this.form.get('gender_type').value;
  }

  generateShortname() {
    if (this.autoShortname) {
      const parts = [];
      parts.push(this.form.get('first_name').value);
      parts.push(this.form.get('last_name').value.substring(0, 1));
      this.form.get('short_name').setValue(parts.filter(p => !!p).join(''));
    }
  }

  vehicleAttached(): boolean {
    const newv = this.form.get('vehicle_uuid').value;
    const oldv = (this.user && this.user.vehicle) ? this.user.vehicle.vehicle_uuid : null;
    return newv && (newv != oldv);
  }

  vehicleUnattached(): boolean {
    const newv = this.form.get('vehicle_uuid').value;
    const oldv = (this.user && this.user.vehicle) ? this.user.vehicle.vehicle_uuid : null;
    return !newv && oldv;
  }

  wearableAttached(): boolean {
    const neww = this.form.get('wearable_uuid').value;
    const oldw = (this.user && this.user.wearable) ? this.user.wearable.wearable_uuid : null;
    return neww && (neww != oldw);
  }

  wearableUnattached(): boolean {
    const neww = this.form.get('wearable_uuid').value;
    const oldw = (this.user && this.user.wearable) ? this.user.wearable.wearable_uuid : null;
    return !neww && oldw;
  }

  onSubmit() {

    const value = this.form.value;

    const params = {
      first_name: value.first_name,
      last_name: value.last_name,
      short_name: value.short_name,
      email: value.email,
      age_type: value.age_type,
      gender_type: value.gender_type,
      project_id: this.dataService.project.project_id
    }

    if (this.user && this.user.short_id) {
      params['short_id'] = this.user.short_id;
    }

    let attVehicleFn: (r) => Observable<any> = r => of(true);

    if (this.vehicleAttached()) {
      attVehicleFn = (r) => {
        return this.dataService.attachVehicle(r.short_id, this.form.get('vehicle_uuid').value);
      };
    } else if (this.vehicleUnattached()) {
      attVehicleFn = (r) => {
        return this.dataService.detachVehicle(r.short_id, this.user.vehicle.vehicle_uuid);
      }
    }

    let attWearableFn: (r) => Observable<any> = r => of(true);

    if (this.wearableAttached()) {
      attVehicleFn = (r) => {
        return this.dataService.attachWearable(r.short_id, this.form.get('wearable_uuid').value);
      }
    } else if (this.wearableUnattached()) {
      attWearableFn = (r) => {
        return this.dataService.detachWearable(r.short_id, this.user.wearable.wearable_uuid);
      };
    }

    this.sub = this.dataService.editUser(params).pipe(
      switchMap(r => combineLatest([attVehicleFn(r), attWearableFn(r)]))
    ).subscribe(result => {
      this.modal.close('saved');
    }, err => {
      const ref = this.modalService.open(InfoModalComponent, {
        backdrop: 'static'
      });
      console.log(err);
      ref.componentInstance.message = err.error.error_description || 'Something went wrong!';
      ref.componentInstance.title = 'Failure';

      ref.result.then(value => {
        this.modal.close('error');
      }, () => { });
    });
  }

  addVehicle() {
    this.modalService.open(VehicleEditComponent).result.then(result => {
      this.setVehicle(result);
      this.getVehicles().subscribe();
    }, () => { });
  }

  setVehicle(v: any) {
    this.form.get('vehicle_uuid').setValue((v && v.vehicle_uuid) || v);
  }

  getVehicles(): Observable<any[]> {
    return this.dataService.getVehicle().pipe(
      tap(result => this.vehicles = result)
    )
  }

  get vehicle() {
    return this.vehicles.find(v => (v.vehicle_uuid == this.form.get('vehicle_uuid').value) || (v == this.form.get('vehicle_uuid').value));
  }


  setWearable(w: any) {
    this.form.get('wearable_uuid').setValue((w && w.wearable_uuid) || w);
  }

  addWearable() {
    this.modalService.open(WearableEditComponent).result.then(result => {
      this.setWearable(result);
      this.getWearables().subscribe();
    }, () => { });
  }

  getWearables(): Observable<any[]> {
    return this.dataService.getWearable().pipe(
      tap(result => this.wearables = result)
    )
  }

  get wearable() {
    return this.wearables.find(w => w.wearable_uuid == this.form.get('wearable_uuid').value);
  }

}

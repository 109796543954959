import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../services/data.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['./vehicle-edit.component.scss']
})
export class VehicleEditComponent implements OnInit {

  vehicle: any;
  form: FormGroup;
  sub: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private dataService: DataService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {

    const vehicle = this.vehicle || {};

    this.form = this.fb.group({
      license_plate: [vehicle.license_plate || ''],
      vehicle_steer_type: [vehicle.vehicle_steer_type || 'left'],
      vehicle_type: [vehicle.vehicle_type || 'car']
    });

  }

  get type() {
    return this.form.get('vehicle_type').value;
  }

  get steering() {
    return this.form.get('vehicle_steer_type').value;
  }

  setType(type) {
    this.form.get('vehicle_type').setValue(type);
  }

  setSteering(steering) {
    this.form.get('vehicle_steer_type').setValue(steering);
  }

  onSubmit() {
    let params = Object.assign({}, this.form.value);

    if (this.vehicle && this.vehicle.vehicle_uuid) {
      params['vehicle_uuid'] = this.vehicle.vehicle_uuid;
    }
    if (this.dataService.project) {
      params['project_id'] = this.dataService.project.project_id;
    }

    this.sub = this.dataService.editVehicle(params).pipe(

    ).subscribe(result => {
      this.modal.close(params);
    });
  }
}

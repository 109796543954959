import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    if (!request.url.endsWith('oauth/auth')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (currentUser && currentUser.access_token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.access_token}`
          }
        });
      }

      //return next.handle(proxyReq);
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if ((event instanceof HttpErrorResponse) && (event.status === 403 || event.status === 401)) {
            this.auth.logout();
            window.location.replace('/login');
          }
          return event;
        })
      );

    } else {
      return next.handle(request);
    }
  }
}

<div class="modal-header">
    <h4>{{user ? 'Edit' : 'Add new'}} user</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-row">
            <div class="form-group col-6">
                <label for="firstname">First Name</label>
                <input type="text" class="form-control" id="firstname" placeholder="Enter first name"
                    formControlName="first_name" autocomplete="new-name" (keyup)="generateShortname()">
            </div>
            <div class="form-group col-6">
                <label for="lastname">Last Name</label>
                <input type="text" class="form-control" id="lastname" placeholder="Enter last name"
                    formControlName="last_name" autocomplete="new-name" (keyup)="generateShortname()">
            </div>
        </div>
        <div class="form-group">
            <label for="shortname">Short Name</label>
            <input type="text" class="form-control" id="shortname" placeholder="Enter short name"
                formControlName="short_name" (change)="autoShortname = false">
            <small class="text-danger" *ngIf="form.get('short_name').hasError('maxlength')">
                The maximum length for this field is {{form.get('short_name').errors.maxlength.requiredLength}}
                characters.
            </small>
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input type="text" class="form-control" id="email" placeholder="Enter email" formControlName="email">
        </div>

        <div class="form-row">
            <div class="form-group col-6">
                <label for="agetype">Age Type</label>
                <div ngbDropdown id="agetype">
                    <button type="button" ngbDropdownToggle class="btn btn-outline-secondary w-100">{{age |
                        dict}}</button>
                    <div ngbDropdownMenu>
                        <button type="button" ngbDropdownItem (click)="setAgeType('below_30')">Below 30</button>
                        <button type="button" ngbDropdownItem (click)="setAgeType('above_30')">Above 30</button>
                    </div>
                </div>

            </div>
            <div class="form-group col-6">
                <label for="gender">Gender</label>
                <div ngbDropdown id="gender">
                    <button type="button" ngbDropdownToggle class="btn btn-outline-secondary w-100">{{gender |
                        dict}}</button>
                    <div ngbDropdownMenu>
                        <button type="button" ngbDropdownItem (click)="setGender('male')">Male</button>
                        <button type="button" ngbDropdownItem (click)="setGender('female')">Female</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-6">
                <label for="vehicle">Vehicle</label>
                <div ngbDropdown id="vehicle">
                    <button type="button" ngbDropdownToggle class="btn btn-outline-secondary w-100">
                        {{vehicle ? (vehicle.vehicle_type|dict) + ': ' + vehicle.license_plate : 'None'}}
                    </button>
                    <div ngbDropdownMenu>
                        <button type="button" ngbDropdownItem (click)="setVehicle(null)">None</button>
                        <button type="button" ngbDropdownItem (click)="addVehicle()">Add...</button>
                        <button *ngFor="let v of vehicles" type="button" ngbDropdownItem
                            (click)="setVehicle(v)">{{v.vehicle_type|dict}}: {{v.license_plate}}</button>
                    </div>
                </div>
            </div>
            <div class="form-group col-6">
                <label for="wearable">Wearable</label>
                <div ngbDropdown id="wearable">
                    <button type="button" ngbDropdownToggle class="btn btn-outline-secondary w-100">
                        {{wearable ? wearable.wearable_mac : 'None'}}
                    </button>
                    <div ngbDropdownMenu>
                        <button type="button" ngbDropdownItem (click)="setWearable(null)">None</button>
                        <button type="button" ngbDropdownItem (click)="addWearable()">Add...</button>
                        <button *ngFor="let w of wearables" type="button" ngbDropdownItem
                            (click)="setWearable(w)">{{w.wearable_mac}}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right">
            <button class="btn btn-warning text-white px-4" [disabled]="(sub && !sub.closed) || form.invalid">{{user ?
                'Save' : 'Add'}}</button>
        </div>
    </form>
</div>
<div class="wrapper" style="">

  <div *ngIf="!logged" style="display: flex;flex-direction: column;align-items: center;">
    <div style="margin-bottom: 60px; text-align: center"><img style="height: auto; width: 440px;"
                                                              src="/assets/images/iDreamsLogo.svg" class="" alt="">
    </div>

    <form action="" (submit)="login()">
      <div class="signintext">Sign In</div>
      <input type="text" name="username" [(ngModel)]="model.username" class="form-control grayinput mb-3"
             placeholder="E-mail">
      <input type="password" name="password" [(ngModel)]="model.password" class="form-control grayinput mb-3"
             placeholder="Password">
      <p *ngIf="error" class="text-danger small">Wrong username or password</p>
      <button type="submit" class="btn btn-warning text-white w-100">SIGN IN</button>
      <!-- <div class="forgottext">Forgot password?</div> -->
    </form>
    <!-- <div class="signuptext">
      Don't have an account? <span class="signupactivetext">Sign Up</span>
    </div> -->
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserEditComponent } from "../user-edit/user-edit.component";
import { DataService } from "../../services/data.service";
import { Subscription } from "rxjs";
import { InfoModalComponent } from '../modals/info/info.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: any[] = [];
  sub: Subscription;

  page: number = 1;
  pageSize: number = 10;

  constructor(
    private dataService: DataService,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.updateList();
  }

  updateList() {
    this.sub = this.dataService.getUser().subscribe(result => {
      this.users = result;
    });
  }

  editUser(user: any = null) {
    const ref = this.modal.open(UserEditComponent);
    if (user) {
      ref.componentInstance.user = user;
    }
    ref.result.then(value => {
      this.updateList();
    }, () => { });
  }

  inviteUser(user: any = null) {
    this.dataService.inviteUser(user).subscribe(result => {
      console.log(result);
      if (result['user_short_ids'] && result['user_short_ids'].length) {
        const ref = this.modal.open(InfoModalComponent, {
          backdrop: 'static'
        });
        ref.componentInstance.message = 'User has been successfully invited';
        ref.componentInstance.title = 'Invitation';

        ref.result.then(value => {
          if (value && value !== 'error') this.updateList();
        }, () => { });
      }
    }, err => {
      const ref = this.modal.open(InfoModalComponent);
      ref.componentInstance.message = 'Invitation does not reach out';
      ref.componentInstance.title = 'Invitation Failed';
    });
  }

  get loading() {
    return this.sub && !this.sub.closed;
  }

}

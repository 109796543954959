import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-choose-project',
  templateUrl: './choose-project.component.html',
  styleUrls: ['./choose-project.component.scss']
})
export class ChooseProjectComponent implements OnInit {

  projects: any[] = [];
  project: any = null;
  backurl = '/';

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private storage: StorageService
  ) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {

      if (params['backurl']) {
        this.backurl = params['backurl'];
      }

      this.dataService.getProjectList().subscribe((data) => {
        this.projects = data;
        this.project = this.projects[0];
      });
    });

  }

  findParams(object, key) {
    let value;
    let that = this;
    Object.keys(object).some(function (k) {
      if (k === key) {
        value = object[k];
        return true;
      }
      if (object[k] && typeof object[k] === 'object') {
        value = that.findParams(object[k], key);
        return value !== undefined;
      }
    });
    return value;
  }

  chooseProject() {
    this.storage.set('project', this.project);
    this.dataService.project = null;
    console.log(this.backurl);
    this.router.navigateByUrl(this.backurl);
  }

  cancel() {
    this.authService.logout();
    this.router.navigate(['login']);
  }
}

<div class="container">
    <div class="shadowed-block">
        <div class="toolbar">
            <h2>Vehicles</h2>
            <button class="btn btn-warning text-white" (click)="editVehicle()">Add Vehicle</button>
        </div>


        <table>
            <thead>
            <tr>
                <th>License Plate</th>
                <th>Type</th>
                <th>Steering</th>
                <th></th>
            </tr>
            </thead>

            <tbody *ngIf="!loading">
            <tr *ngFor="let v of vehicles | slice: (page-1) * pageSize : page * pageSize">
                <td>{{v.license_plate}}</td>
                <td>{{v.vehicle_type | dict}}</td>
                <td>{{v.vehicle_steer_type | dict}}</td>
                <td><img class="m-2 pointer" src="assets/images/ic_edit.svg" (click)="editVehicle(v)"></td>
            </tr>
            <tr *ngIf="!vehicles.length">
                <td colspan="4" class="text-center">Nothing to display</td>
            </tr>
            </tbody>

            <tbody *ngIf="loading">
            <tr><td colspan="4" class="text-center blink">Loading...</td></tr>
            </tbody>


        </table>

        <ngb-pagination class="d-flex justify-content-end mt-4"
                        [(page)]="page" [pageSize]="pageSize" [collectionSize]="vehicles.length"></ngb-pagination>

    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dict'
})
export class DictPipe implements PipeTransform {

  private dict = {
    'above_30': 'Above 30',
    'below_30': 'Below 30',
    'male': 'Male',
    'female': 'Female',
    'car': 'Car',
    'bus': 'Bus',
    'truck': 'Truck',
    'tram': 'Tram',
    'left': 'Left',
    'right': 'Right'
  }

  transform(value: string): string {
    return this.dict[value] || value;
  }

}

<div class="container">
    <div class="shadowed-block">
        <div class="toolbar">
            <h2>Wearables</h2>
            <button class="btn btn-warning text-white" (click)="editWearable()">Add Wearable</button>
        </div>

        <table>
            <thead>
            <tr>
                <th>UUID</th>
                <th>Mac</th>
                <th></th>
            </tr>
            </thead>

            <tbody *ngIf="!loading">
            <tr *ngFor="let w of wearables | slice: (page-1) * pageSize : page * pageSize">
                <td>{{w.wearable_uuid}}</td>
                <td>{{w.wearable_mac}}</td>
                <td><img class="m-2 pointer" src="assets/images/ic_edit.svg" (click)="editWearable(w)"></td>
            </tr>
            <tr *ngIf="!wearables.length">
                <td colspan="3" class="text-center">Nothing to display</td>
            </tr>
            </tbody>

            <tbody *ngIf="loading">
            <tr><td colspan="3" class="text-center blink">Loading...</td></tr>
            </tbody>


        </table>

        <ngb-pagination class="d-flex justify-content-end mt-4"
                        [(page)]="page" [pageSize]="pageSize" [collectionSize]="wearables.length"></ngb-pagination>

    </div>
</div>
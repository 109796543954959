<div class="container">
    <div class="shadowed-block">
        <div class="toolbar">
            <h2>Users</h2>
            <button class="btn btn-warning text-white" (click)="editUser()">Add User</button>
        </div>


        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Short Name</th>
                    <th>Email</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Vehicle</th>
                    <th>Wearable</th>
                    <th>Date Invited</th>
                    <th>Invite to app</th>
                    <th></th>
                </tr>
            </thead>

            <tbody *ngIf="!loading; else loadingTpl">
                <tr *ngFor="let user of users | slice: (page-1) * pageSize : page * pageSize">
                    <td>{{user.short_id}}</td>
                    <td>{{user.first_name}}</td>
                    <td>{{user.last_name}}</td>
                    <td>{{user.short_name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.age_type | dict}}</td>
                    <td>{{user.gender_type | dict}}</td>
                    <td>{{user.vehicle?.license_plate}}</td>
                    <td>{{user.wearable?.wearable_mac}}</td>
                    <td>{{ user.activated_date | date: 'mediumDate' }}</td>
                    <td>
                        <button *ngIf="!user.activated_date" class="btn btn-warning text-white invite-user"
                            (click)="inviteUser(user)">
                            <img class="pr-2" src="assets/images/ic_invite.svg" />
                            <div class="text-no-wrap">Invite</div>
                        </button>

                    </td>
                    <td><img class="m-2 pointer" src="assets/images/ic_edit.svg" (click)="editUser(user)"></td>
                </tr>

                <tr *ngIf="!users.length">
                    <td colspan="12" class="text-center">Nothing to display</td>
                </tr>
            </tbody>

            <ng-template #loadingTpl>
                <tbody>
                    <tr>
                        <td colspan="12" class="text-center blink">Loading...</td>
                    </tr>
                </tbody>
            </ng-template>


        </table>

        <ngb-pagination class="d-flex justify-content-end mt-4" [(page)]="page" [pageSize]="pageSize"
            [collectionSize]="users.length"></ngb-pagination>

    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoModalComponent implements OnInit {

  title: string;
  message: string;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }
}

<div class="modal-header">
    <h4>{{vehicle ? 'Edit' : 'Add new'}} vehicle</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="plate">License Plate</label>
            <input type="text" class="form-control" id="plate" placeholder="Enter license plate"
                formControlName="license_plate">
        </div>

        <div class="form-row">
            <div class="form-group col-6">
                <label for="type">Type</label>
                <div ngbDropdown id="type">
                    <button type="button" ngbDropdownToggle class="btn btn-outline-secondary w-100">{{type |
                        dict}}</button>
                    <div ngbDropdownMenu>
                        <button type="button" ngbDropdownItem (click)="setType('car')">Car</button>
                        <button type="button" ngbDropdownItem (click)="setType('bus')">Bus</button>
                        <button type="button" ngbDropdownItem (click)="setType('truck')">Truck</button>
                        <button type="button" ngbDropdownItem (click)="setType('tram')">Tram</button>
                    </div>
                </div>

            </div>
            <div class="form-group col-6">
                <label for="steering">Steering Type</label>
                <div ngbDropdown id="steering">
                    <button type="button" ngbDropdownToggle class="btn btn-outline-secondary w-100">{{steering |
                        dict}}</button>
                    <div ngbDropdownMenu>
                        <button type="button" ngbDropdownItem (click)="setSteering('left')">Left</button>
                        <button type="button" ngbDropdownItem (click)="setSteering('right')">Right</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-right">
            <button class="btn btn-warning text-white px-4" [disabled]="sub && !sub.closed">{{vehicle ? 'Save' :
                'Add'}}</button>
        </div>

    </form>
</div>
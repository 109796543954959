<div class="modal-header">
    <h4>{{wearable ? 'Edit' : 'Add new'}} wearable</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="uuid">UUID</label>
            <input type="text" class="form-control" id="uuid" placeholder="Enter UUID"
                   formControlName="wearable_uuid">
        </div>
        <div class="form-group">
            <label for="mac">Wearable Mac</label>
            <input type="text" class="form-control" id="mac" placeholder="Enter Mac"
                   formControlName="wearable_mac">
        </div>

        <div class="text-right">
            <button class="btn btn-warning text-white px-4" [disabled]="sub && !sub.closed">{{wearable ? 'Save' : 'Add'}}</button>
        </div>

    </form>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./components/login/login.component";
import { UserComponent } from "./components/user/user.component";
import { UserGuard } from "./services/user.guard";
import { UsersComponent } from "./components/users/users.component";
import { VehiclesComponent } from "./components/vehicles/vehicles.component";
import { WearablesComponent } from "./components/wearables/wearables.component";
import { ChooseProjectComponent } from './components/choose-project/choose-project.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'choose-project',
    pathMatch: 'full',
    component: ChooseProjectComponent
  },
  {
    path: '',
    component: UserComponent,
    canActivate: [UserGuard],
    // resolve: [UserGuard],
    canActivateChild: [UserGuard],
    data: {
      roles: ['planner', 'projectleader']
    },
    children: [
      {
        path: '',
        component: UsersComponent
      },
      {
        path: 'vehicles',
        component: VehiclesComponent
      },
      {
        path: 'wearables',
        component: WearablesComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "../../services/data.service";

@Component({
  selector: 'app-wearable-edit',
  templateUrl: './wearable-edit.component.html',
  styleUrls: ['./wearable-edit.component.scss']
})
export class WearableEditComponent implements OnInit {

  wearable: any;
  form: FormGroup;
  sub: Subscription;

  constructor(
    public modal: NgbActiveModal,
    private dataService: DataService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    const wearable = this.wearable || {};

    this.form = this.fb.group({
      wearable_uuid: [{ value: wearable.wearable_uuid || '', disabled: !!this.wearable }],
      wearable_mac: [wearable.wearable_mac || ''],
    });

  }

  onSubmit() {
    let params = Object.assign({}, this.form.value);

    if (this.wearable && this.wearable.wearable_uuid) {
      params['wearable_uuid'] = this.wearable.wearable_uuid;
    }

    if (this.dataService.project) {
      params['project_id'] = this.dataService.project.project_id;
    }

    this.sub = this.dataService.editWearable(params).pipe(

    ).subscribe(result => {
      this.modal.close(params);
    });
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: '/api',
  // apiUrl: 'http://ec2-52-215-236-153.eu-west-1.compute.amazonaws.com/api',
  // saikuUrl: 'https://imobwww.uhasselt.be/saikutest/saiku/rest/saiku/embed'
  apiUrl: window["env"]["apiUrl"] || "http://ec2-52-215-236-153.eu-west-1.compute.amazonaws.com/api",
  saikuUrl: window["env"]["saikuUrl"] || "https://imobwww.uhasselt.be/saikutest/saiku/rest/saiku/embed"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
